import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Snackbar, Alert } from '@mui/material';
import Lottie from 'lottie-react';
import referral_lottie from '../lottie/task.json';

const EranPage = () => {
  const [tasks, setTasks] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
    vertical: 'top',
    horizontal: 'center',
  });

  const tg = window.Telegram.WebApp;
  const initDataUnsafe = tg.initDataUnsafe; // parsed data

  useEffect(() => {
    fetchData();
  }, []);

  const fetchTasks = async () => {
    try {
      const tasksResponse = await axios.get('https://xcosmos.top/api/tasks');
      setTasks(tasksResponse.data); 
    } catch (error) {
      setError('Failed to fetch tasks');
    }
  };

  const fetchUserData = async () => {
    try {
      const userResponse = await axios.post('https://xcosmos.top/api/user', {
        initDataUnsafe: initDataUnsafe
      });
      setUser(userResponse.data);
    } catch (error) {
      setError('Failed to fetch user data');
    }
  };

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([fetchTasks(), fetchUserData()]);
    setLoading(false);
  };

  const getFilteredTasks = () => {
    if (!user || !tasks.length) return [];
    const completedTaskIds = user.tasks ? user.tasks.split(',').map(taskId => parseInt(taskId.trim())) : [];
    return tasks.map(task => ({
      ...task,
      isCompleted: completedTaskIds.includes(task.id),
    }));
  };

  const handleTaskClick = async (task) => {
    window.open(task.task_url, '_blank');
    
    try {
      const response = await axios.post('https://xcosmos.top/api/user/complete_task', {
        initDataUnsafe: initDataUnsafe,
        taskId: task.id,
        type: task.type,
      });
      
      if (response.data.success) {
        setSnackbar({ open: true, message: 'Task completed successfully', severity: 'success' });
        fetchData();  // Refresh data to update the completed tasks
      } else {
        setSnackbar({ open: true, message: response.data.message || 'Error completing task', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Error completing task', severity: 'error' });
    }
  };

  const filteredTasks = getFilteredTasks();

  return (
    <>
      <Box
        sx={{
          background:'#000',
          backgroundImage: `
          radial-gradient(circle at 40% 50%, #610b4b 0%, #a30d8a00 40%),
          radial-gradient(circle at 65% 60%, #420a50 0%, #580e7500 45%),
          radial-gradient(circle at 40% 60%, #076066 0%, #17777700 45%)`,
        backgroundBlendMode: 'screen',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          marginBottom: '56px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          boxShadow={'0 0 8px 2px rgba(0, 150,255, 0.3)'}
          sx={{
            borderRadius: '0 0 20px 20px',
            background: 'rgba(0, 10, 50, 0.5)',
            width: '100%',
            minHeight: '60px',
            marginTop: '0',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
            flexDirection: 'column',
          }}
        >
          <Lottie
            animationData={referral_lottie}
            loop={true}
            style={{ width: 150, height: 150 }}
          />

          <Typography variant='h7' color={'white'} padding={"10px"}>
            Available Tasks: {filteredTasks.filter(task => !task.isCompleted).length}
          </Typography>
        </Box>

        <Box
          sx={{
            borderRadius: '20px',
            background: 'rgba(20, 20, 20, 0.5)',
            width: '98%',
            minHeight: '60px',
            height: '100%',
            marginTop: '50px',
            marginBottom: '50px',
            alignItems: 'center',
            justifyItems: 'center',
            padding: '20px',
          }}
        >
          <Typography variant='p' color={'white'} textAlign={'center'}>
            Complete all the tasks to earn more points 
          </Typography>

          {loading ? (
            <Typography color="white">Loading...</Typography>
          ) : error ? (
            <Typography color="red">{error}</Typography>
          ) : (
            filteredTasks.map(task => (
              <Box
                key={task.id}
                boxShadow={'0 0 8px 2px rgba(0 , 150,255, 0.15)'}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  borderRadius: '20px 20px 20px 20px',
                  background: 'rgba(20, 20, 20, 0.5)',
                  width: '100%',
                  minHeight: '60px',
                  height: 'auto',
                  marginTop: '30px',
                  alignItems: 'center',
                  color: 'white',
                  opacity: task.isCompleted ? 0.5 : 1,
                  cursor: task.isCompleted ? 'default' : 'pointer',
                }}
                onClick={() => !task.isCompleted && handleTaskClick(task)}
              >
                <Box
                  margin={'5px'}
                  component="img"
                  sx={{ width: '30px', height: '30px' }}
                  alt="task"
                  src={task.image_url}
                />
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    padding: '15px',
                    overflowWrap: 'break-word',
                    color: '#a6a6a6',
                  }}
                  maxWidth={'60%'}
                  variant='p'
                >
                  {task.info_text || 'No information available'}
                </Typography>

                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    padding: '15px',
                    overflowWrap: 'break-word',
                    color: '#a6a6a6',
                  }}
                  maxWidth={'60%'}
                  variant='p'
                >
                🌟{task.xp || '0'}xp
                </Typography>

                <Typography
                  sx={{
                   textShadow:'2px 2px 15px #0080ff' ,
                    overflowWrap: 'break-word',
                    color: task.isCompleted ? 'gray' : 'white',
                  }}
                  variant='p'
                >
                  {task.isCompleted ? 'Done' : `+${task.reward}`}
                </Typography>
              </Box>
            ))
          )}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal:'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EranPage;
