import React, { useEffect, useState } from 'react';
import { Drawer, Box, Typography, Grid, Card, CardContent, Button, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import Lottie from 'lottie-react';
import congratsAnimation from '../lottie/congrats.json'; // Your Lottie animation file

const tg = window.Telegram.WebApp;
const initDataUnsafe = tg.initDataUnsafe; // parsed data

const cardsData = Array.from({ length: 20 }).map((_, index) => ({
  id: index + 1,
  title: `Level ${index + 1}`,
  description: `LevelUp now!`,
}));

const levelInfo = [
  { level: 1, points: 1000, xp: 300 },
  { level: 2, points: 1250, xp: 350 },
  { level: 3, points: 1563, xp: 400 },
  { level: 4, points: 1953, xp: 450 },
  { level: 5, points: 2441, xp: 500 },
  { level: 6, points: 3051, xp: 550 },
  { level: 7, points: 3814, xp: 600 },
  { level: 8, points: 4768, xp: 650 },
  { level: 9, points: 5960, xp: 700 },
  { level: 10, points: 7450, xp: 750 },
  { level: 11, points: 9313, xp: 800 },
  { level: 12, points: 11641, xp: 850 },
  { level: 13, points: 14552, xp: 900 },
  { level: 14, points: 18190, xp: 950 },
  { level: 15, points: 22738, xp: 1000 },
  { level: 16, points: 28422, xp: 1050 },
  { level: 17, points: 35527, xp: 1100 },
  { level: 18, points: 44408, xp: 1150 },
  { level: 19, points: 55510, xp: 1200 },
  { level: 20, points: 69388, xp: 1250 },
];

const Upgrade = ({ open, onClose }) => {
  const [userData, setUserData] = useState({ level: 0, xp: 0 });
  const [loading, setLoading] = useState(true);
  const [upgrading, setUpgrading] = useState(false); 
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false); // Track whether to show the congrats animation

  useEffect(() => {
    // Fetch user data
    const fetchUserData = async () => {
      try {
        const response = await axios.post('https://xcosmos.top/api/user', {
          initDataUnsafe: initDataUnsafe,
        });
        setUserData(response.data);
      } catch (error) {
        let errorMessage = 'Failed to fetch user data';
        if (error.response && error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error; // Extract specific error message
        } else if (error.message) {
          errorMessage = error.message;
        }
        handleError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchUserData();
    }
  }, [open]);

  const handleError = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSuccess = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleUpgrade = async (level) => {
    setUpgrading(true);
    try {
      const response = await axios.post('https://xcosmos.top/api/user/LevelUp', {
        initDataUnsafe: initDataUnsafe,
      });
      setUserData(response.data);

      if (response.data.level >= 20) {
        setShowCongrats(true); // Show congrats animation if user reaches max level
      } else {
        handleSuccess(`Successfully upgraded to Level ${level}`);
      }
    } catch (error) {
      let errorMessage = 'Failed to upgrade level';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error; // Extract specific error message
      } else if (error.message) {
        errorMessage = error.message;
      }
      handleError(errorMessage);
    } finally {
      setUpgrading(false);
    }
  };

  const toggleInfoModal = () => {
    setInfoModalOpen(!infoModalOpen);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          position: 'relative',
          padding: '16px',
          boxShadow: '0px -4px 5px rgba(255, 255, 255, 0.6)',
          background: '#000',
          backgroundImage: `
            radial-gradient(circle at 40% 50%, #610b4b 0%, #a30d8a00 40%),
            radial-gradient(circle at 65% 60%, #420a50 0%, #580e7500 45%),
            radial-gradient(circle at 40% 60%, #076066 0%, #17777700 45%)`,
          backgroundBlendMode: 'screen',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        },
      }}
    >
      {/* Close Button */}
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 15,
          right: 8,
          color: 'white',
        }}
      >
        <CloseIcon />
      </IconButton>

      {/* Info Button */}
      <IconButton
        onClick={toggleInfoModal}
        sx={{
          position: 'absolute',
          top: 15,
          left: 8,
          color: 'white',
        }}
      >
        <InfoIcon />
      </IconButton>

      {showCongrats || userData.level >= 20 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '16px',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            borderRadius: '15px',
            mt: 4,
          }}
        >
          <Lottie animationData={congratsAnimation} loop={true} style={{ width: 200, height: 200 }} />
          <Typography variant="h7" color="white" sx={{ mt: 2 }}>
            Congratulations! You've reached the maximum level!
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography color={'white'}>🚀Level: {userData.level}</Typography>
            <Typography color={'white'}>🌟Available XP: {userData.xp}</Typography>
          </Box>

          <Grid container spacing={2}>
            {cardsData.map((card) => {
              const isAchieved = card.id <= userData.level;
              const isNextLevel = card.id === userData.level + 1;
              const isFutureLevel = card.id > userData.level + 1;

              let cardDescription = card.description;
              if (isAchieved) {
                cardDescription = 'Achieved';
              } else if (isFutureLevel) {
                cardDescription = `Upgrade to Level ${userData.level + 1} first`;
              }

              return (
                <Grid item xs={6} key={card.id}>
                  <Card
                    sx={{
                      borderRadius: '15px',
                      textAlign: 'center',
                      backgroundColor: '#f2f0f0',
                      height: '180px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      opacity: isAchieved ? 0.5 : 1,
                    }}
                  >
                    <Box
                      sx={{
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        animation: isAchieved ? 'none' : 'glow 1.5s ease-in-out infinite',
                        '@keyframes glow': {
                          '0%': { textShadow: '0 0 10px #fff, 0 0 20px #ff00ff, 0 0 30px #ff00ff' },
                          '50%': { textShadow: '0 0 20px #fff, 0 0 30px #ff00ff, 0 0 40px #ff00ff' },
                          '100%': { textShadow: '0 0 10px #fff, 0 0 20px #ff00ff, 0 0 30px #ff00ff' },
                        },
                      }}
                    >
                      {card.title}
                    </Box>
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {cardDescription}
                      </Typography>
                    </CardContent>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        margin: '8px',
                        backgroundImage: 'linear-gradient(to right, #FF512F 0%, #DD2476 51%, #FF512F 100%)',
                      }}
                      onClick={() => handleUpgrade(card.id)}
                      disabled={isAchieved || isFutureLevel || upgrading}
                    >
                      {upgrading && isNextLevel ? 'Upgrading...' : 'Upgrade'}
                    </Button>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {/* Modal for Info */}
      <Modal
        open={infoModalOpen}
        onClose={toggleInfoModal}
        aria-labelledby="level-info-title"
        aria-describedby="level-info-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            maxHeight: 400,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
          }}
        >
          <Typography id="level-info-title" variant="h6" component="h2">
            Level Reward Info
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Level</TableCell>
                  <TableCell align="right">🚀Points</TableCell>
                  <TableCell align="right">🚀🌟XP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {levelInfo.map((level) => (
                  <TableRow key={level.level}>
                    <TableCell component="th" scope="row">
                      {level.level}
                    </TableCell>
                    <TableCell align="right">{level.points}</TableCell>
                    <TableCell align="right">{level.xp}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Button onClick={toggleInfoModal} sx={{ mt: 2 }} variant="contained">
            Close
          </Button>
        </Box>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackbarMessage.includes('Failed') ? 'error' : 'info'} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Drawer>
  );
};

export default Upgrade;
