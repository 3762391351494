import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Modal, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Web3 from 'web3';
import BN from 'bn.js'; // Use bn.js for large number calculations
import { margin } from '@mui/system';

const TransferModal = ({ open, onClose, asset, walletAddress, privateKey }) => {
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [gasFee, setGasFee] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  const [disableModalClose, setDisableModalClose] = useState(false);

  useEffect(() => {
    const fetchGasFee = async () => {
      if (!walletAddress || !asset || !amount || !recipient) return;

      try {
        const web3 = new Web3(asset.rpcUrl);
        const gasPrice = new BN(await web3.eth.getGasPrice());
        const gasLimit = asset.isToken ? new BN(60000) : new BN(21000);

        const estimatedGas = gasPrice.mul(gasLimit);
        setGasFee(web3.utils.fromWei(estimatedGas.toString(), 'ether'));
      } catch (error) {
        console.error('Error fetching gas fee:', error);
        setError('Error fetching gas fee');
      }
    };

    fetchGasFee();
  }, [walletAddress, asset, amount, recipient]);

  const handleTransfer = async () => {
    try {
      setConfirming(true);
      setDisableModalClose(true);
      const web3 = new Web3(asset.rpcUrl);

      // Check the native token balance (ETH, MATIC, etc.)
      const balance = new BN(await web3.eth.getBalance(walletAddress));
      console.log('Native Token Balance (ETH/MATIC):', web3.utils.fromWei(balance.toString(), 'ether'));

      const gasPrice = new BN(await web3.eth.getGasPrice());
      const gasLimit = asset.isToken ? new BN(60000) : new BN(21000);
      const estimatedGasFee = gasPrice.mul(gasLimit);

      if (balance.lt(estimatedGasFee)) {
        throw new Error('Insufficient native currency (ETH/MATIC) to cover gas fees.');
      }

      if (!privateKey || privateKey === '') {
        throw new Error('Private key is missing or invalid.');
      }

      const transferAmount = Web3.utils.toWei(amount, 'ether'); // Convert user input amount to Wei
      console.log('Transfer Amount:', transferAmount);

      let receipt;

      if (asset.isToken) {
        const contract = new web3.eth.Contract(
          [
            {
              constant: false,
              inputs: [
                { name: 'to', type: 'address' },
                { name: 'value', type: 'uint256' }
              ],
              name: 'transfer',
              outputs: [{ name: '', type: 'bool' }],
              payable: false,
              stateMutability: 'nonpayable',
              type: 'function'
            }
          ],
          asset.contract
        );

        const tx = {
          from: walletAddress,
          to: asset.contract,
          data: contract.methods.transfer(recipient, transferAmount).encodeABI(),
          gas: gasLimit.toString(),
          gasPrice: gasPrice.toString()
        };

        const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);
        receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

        console.log('Token transfer successful:', receipt);
        setSuccess('Token transfer successful!');
      } else {
        const tx = {
          from: walletAddress,
          to: recipient,
          value: transferAmount,
          gas: gasLimit.toString(),
          gasPrice: gasPrice.toString()
        };

        const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);
        receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

        console.log('Coin transfer successful:', receipt);
        setSuccess('Coin transfer successful!');
      }

      // Ensure transaction details are correctly set
      if (receipt) {
        setTransactionDetails(receipt);
      }

      // Open the transaction details modal
      setTransactionModalOpen(true);
    } catch (error) {
      console.error('Error transferring asset:', error);
      setError(`Error transferring asset: ${error.message}`);
    } finally {
      setConfirming(false);
      setDisableModalClose(false);
    }
  };

  const handleCloseSnackbar = () => {
    setError(null);
    setSuccess(null);
  };

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmTransfer = () => {
    handleTransfer();
    setConfirmDialogOpen(false);
  };

  const handleCloseTransactionModal = () => {
    setTransactionModalOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={disableModalClose ? () => {} : onClose}
        sx={{
        
            width: '100%',

            
 
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
          }}
        >
          <Typography variant="h6" component="h2">
            Transfer {asset.name}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Recipient Address"
            variant="outlined"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Amount"
            variant="outlined"
            type="number" // Ensures proper numeric input
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          {gasFee && (
            <Typography sx={{ mt: 2 }}>
              Estimated Gas Fee: {gasFee} {asset.symbol}
            </Typography>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={confirming || !recipient || !amount}
            onClick={handleOpenConfirmDialog}
          >
            {confirming ? 'Confirming...' : 'Send'}
          </Button>
        </Box>
      </Modal>
      
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>Confirm Transaction</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to send {amount} {asset.name} to {recipient}.
            {gasFee && ` The estimated gas fee is ${gasFee} ${asset.symbol}.`}
            Do you want to proceed with the transaction?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmTransfer} color="primary" disabled={confirming}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={transactionModalOpen}
        onClose={handleCloseTransactionModal}
        sx={{
          '@media (max-width:600px)': {
            width: '90%',
            maxWidth: '400px',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: '500px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
          }}
        >
          <Typography variant="h6" component="h2">
            Transaction Successful
          </Typography>
          {transactionDetails && (
            <>
              <Typography sx={{ mt: 2 ,  overflowWrap: 'break-word', }}>
                Transaction Hash: {transactionDetails.transactionHash}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Block Number: {transactionDetails.blockNumber}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Gas Used: {transactionDetails.gasUsed}
              </Typography>
            </>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleCloseTransactionModal}
          >
            Close
          </Button>
        </Box>
      </Modal>
      
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar open={!!success} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TransferModal;
