import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import HomeNavigation from './navigation/HomeNavigation';
import EranNavigation from './navigation/EranNavigation';
import InviteNavigation from './navigation/InviteNavigation';
import WalletNavigation from './navigation/WalletNavigation';
import HomePage from './pages/HomePage';
import EranPage from './pages/EranPage';
import InvitePage from './pages/InvitePage';
import WalletPage from './pages/WalletPage';
import LoadingPage from './pages/LoadingPage'; // Import the LoadingPage component

export default function FixedBottomNavigation() {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state
  const [dataLoaded, setDataLoaded] = useState(false); // State to check if data is loaded

  // tg
  const tg = window.Telegram.WebApp;

  useEffect(() => {
    tg.expand();

    // Simulate a data loading process
    const loadData = async () => {
      // Simulate an async operation (e.g., fetching data)
      await new Promise((resolve) => setTimeout(resolve, 1500));

      // Once the data is loaded, set dataLoaded to true
      setDataLoaded(true);
      setLoading(false); // Set loading to false once data is loaded
    };

    loadData();
  }, []);

  const renderPage = () => {
    switch (value) {
      case 0:
        return <HomePage />;
      case 1:
        return <EranPage />;
      case 2:
        return <InvitePage />;
      case 3:
        return <WalletPage />;
      default:
        return <HomePage />;
    }
  };

  if (loading) {
    return <LoadingPage />; // Display the custom loading page
  }

  return (
    <Box sx={{ pb: 0, m: 0 }}>
      <CssBaseline />
      <Box sx={{ p: 0, m: 0 }}>
        {renderPage()}
      </Box>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'transparent', // Transparent background
          border: '2px solid #6A1B9A', // Dark purple border
          borderTopLeftRadius: 16, // Rounded corners
          borderTopRightRadius: 16,
          borderBottom: 'none', // Remove bottom border
          boxShadow: '0 0 15px rgba(106, 27, 154, 0.6)', // Shiny effect
          backdropFilter: 'blur(10px)', // Optional: adds a blur effect behind the navigation
          zIndex: 1000,
        }}
        elevation={0} // Remove the default elevation to avoid double shadows
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
          
            backgroundColor: '#000000', // Transparent background for BottomNavigation
            borderRadius: 0, // Ensure no rounded corners on BottomNavigation
            '.MuiBottomNavigationAction-root': {
              color: '#9E9E9E', // Default color for inactive icons and text
              '&.Mui-selected': {
                color: '#6A1B9A', // Color for active icon and text
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // White-transparent background for selected item
                borderRadius: 2, // Optional: rounded corners for the frame effect
                boxShadow: '0 0 0px rgba(255, 255, 255, 0.9)', // White-transparent glow effect
              }
            },
          }}
        >
          <HomeNavigation value={value} setValue={setValue} index={0} />
          <EranNavigation value={value} setValue={setValue} index={1} />
          <InviteNavigation value={value} setValue={setValue} index={2} />
          <WalletNavigation value={value} setValue={setValue} index={3} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
