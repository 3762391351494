import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import Logo from '../images/logo.png';
import btn_convert from '../images/coin.gif';
import CircularWaveProgressBar from "./CircularWaveProgressBar";
import '../css/CircularWaveProgressBar.css'; 
import { keyframes } from '@mui/system';
import Starfield from './Starfield';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Upgrade from './Upgrade';
const HomePage = () => {
  const [user, setUser] = useState(null);
  const [level, setLevel] = useState(null);
  const [xp, setXp] = useState(null);
  const [error, setError] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [points, setPoints] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [progress, setProgress] = useState(0); // Percentage value for progress bar
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false); // Add state for bottom sheet

  const pulse = keyframes`
  0% {
    transform: scale(0.99);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;


  const tg = window.Telegram.WebApp;
  const initDataUnsafe = tg.initDataUnsafe; // parsed data

  const truncateString = (input, maxLength) => {
    const str = String(input); // Convert the input to a string
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClick = (message) => {
    setSnackbarMessage(message);
    setOpen(true);
  };

  // Function to close the Snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (initDataUnsafe && initDataUnsafe.user) {
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    let interval = null;
    if (remainingTime > 0) {
      interval = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [remainingTime]);

  useEffect(() => {
    // Calculate progress based on remaining time
    const initialWaitTime = 21600 * 1000; // Assuming 2000 seconds wait time
    if (remainingTime >= 0 && initialWaitTime > 0) {
      const newProgress = Math.max(0, Math.min(100, ((initialWaitTime - remainingTime) / initialWaitTime) * 100));
      setProgress(newProgress);
    }
  }, [remainingTime]);

  const fetchUserData = async () => {
    try {
      const response = await axios.post('https://xcosmos.top/api/user', {
        initDataUnsafe: initDataUnsafe
      });
      const data = response.data;
      setUser(data);
      setPoints(data.coin); // Update the points state correctly
      setLevel(data.level);
      setXp(data.xp);
      const { last_claim } = data;
      const now = Date.now();
      const lastClaimTime = new Date(last_claim * 1000).getTime(); // Convert seconds to milliseconds
      const waitTime = 21600 * 1000; // Assuming 2000 seconds wait time
      const timeLeft = lastClaimTime + waitTime - now;

      if (timeLeft > 0) {
        setRemainingTime(timeLeft);
      } else {
        setRemainingTime(0); // Set to 0 if no time left
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleClaim = async () => {
    if (user) {
      try {
        const response = await axios.post('https://xcosmos.top/api/user/claim', {
          initDataUnsafe: initDataUnsafe
        });
        
        const data = response.data;

  
        setPoints(data.coin); // Update the points state correctly
        setLevel(data.level);
        setXp(data.xp);
     
        setOpenSnackbar(true);
        const { last_claim } = data;
        const now = Date.now();
        const lastClaimTime = new Date(last_claim * 1000).getTime(); // Convert seconds to milliseconds
        const waitTime = 21600 * 1000; // Assuming 2000 seconds wait time
        const timeLeft = lastClaimTime + waitTime - now;

        if (timeLeft > 0) {
          setRemainingTime(timeLeft);
        } else {
          setRemainingTime(0);
        }
      } catch (error) {
        setError(error.response?.data?.error || error.message);
      }
    }
  };

  return (
    <Box
      sx={{
        background: 'rgb(0,10,20,1)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '0',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >


<div>
<Starfield />
</div>


   
<Box
   sx={{
    marginTop: '30px',
    minHeight: '100%',
    display: 'flex',
    width: '90%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.1)', // Transparent background color
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat', // Prevent background from repeating
    backdropFilter: 'blur(4px)',
    borderRadius: '12px', // Smooth edges
    padding: '20px', // Padding inside the box
  }}

>


<Typography 
        textAlign={'center'} 
        variant="h6" 
        sx={{ 
          color: 'white',
          textShadow: '2px 2px 10px #00ffff',
          mb: 2 ,
          zIndex: 1,
        }}
      >
        <span style={{ color: '#ff1493' }}>X</span>wallet
      </Typography> 
      <Box
      
        sx={{
          borderRadius: '20px 20px 20px 20px',
          background: 'rgb(0, 50, 255, 0.2)',
          alignItems: 'center',
          display: 'flex',
          minHeight: '50px',
          flexDirection: 'column',
          width: '100%',
          zIndex: 1,  
            p: '10px'
        }}
      >
        <Box sx={{
          p: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
      
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <Box
              component="img"
              sx={{
                width: '25px',
                height: '30px',
   
              }}
              alt="logo photo"
              src={Logo}
            />
            <Typography
     
              variant="p"
              sx={{
                overflowWrap: 'break-word',
                color: 'white',
                fontSize: '0.9rem',
                fontWeight: 'bold', 
              }}
            >
              {truncateString(user ? user.name : 'Guest', 8)}
            </Typography>  

            
              
          </Box>



          <Typography
            variant="p"
            sx={{
              overflowWrap: 'break-word',
              fontWeight: 'bold', 
              color: 'white',
              fontSize: '0.9rem',
            }}
          >
            🪐 points: {points}
          </Typography>


   
      
        </Box>
        <Box sx={{
          p: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
    
        }}>
          <Typography
            variant="p"
            sx={{
              overflowWrap: 'break-word',
              fontWeight: 'bold', 
              color: 'white',
              fontSize: '0.85rem',
            }}
          >
            🚀 Level: {level}
          </Typography>   
          <Typography
            variant="p"
            sx={{
                fontWeight: 'bold', 
              overflowWrap: 'break-word',
              color: 'white',
              fontSize: '0.85rem',
            }}
          >
            🌟 {xp} xp
          </Typography>
        </Box>
      </Box>
        <CircularWaveProgressBar value={progress} timeValue={formatTime(remainingTime)} />

        <Button
      sx={{
      
        backgroundImage: 'linear-gradient(to right, #FF512F 0%, #DD2476 51%, #FF512F 100%)',
        margin: '10px',
        padding: '15px 45px',
        textAlign: 'center',
        textTransform: 'uppercase',
        backgroundSize: '200% auto',
        color: 'white',
        boxShadow: '0 0 20px #eee',
        borderRadius: '10px',
        display: 'block',
        width: 'auto',
        transition: '1s ease-in-out',
        animation: `${pulse} 2s infinite`, // Add the pulsating animation here
        '&:hover': {
          backgroundPosition: 'right center',
          color: '#fff',
          textDecoration: 'none',
        },
        '&:active': {
          transform: 'scale(0.9)', // Scale down when active
        },
        '&:disabled': {
          backgroundColor: 'rgb(212, 212, 212)',
          color: 'rgba(255, 255, 255, 0.9)',
          backgroundImage: 'none',
          boxShadow: 'none', // Remove shadow when disabled
          transform: 'scale(1)', // Reset scale when disabled
          animation:'none',
        },
      }}
      disabled={remainingTime > 0}
      onClick={handleClaim}
    >
      Claim Now
    </Button>





      </Box>
      <Box
  sx={{
    p:'15px',
    marginBottom: '65px',
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',

    background: 'rgb(0,0,0,0)',
  }}
>
  <Button
   onClick={() => setIsBottomSheetOpen(true)}
    variant="contained" // Use variant="contained" for a solid button
    color="primary" // Adjust color if needed
    startIcon={<RocketLaunchIcon />} // Add the icon
    sx={{
      backgroundImage: 'linear-gradient(to right, #FF512F 0%, #DD2476 51%, #FF512F 100%)',
      margin: '5px',
      padding: '8px 16px', // Reduced padding for a smaller size
      fontSize: '0.8rem', // Reduced font size
      textAlign: 'center',
      textTransform: 'uppercase',
      backgroundSize: '200% auto',
      color: 'white',
      boxShadow: '0 0 15px #eee', // Reduced shadow size
      borderRadius: '8px', // Smaller border-radius for better proportions
      width: '40%',
      transition: '0.5s ease-in-out',
      animation: `${pulse} 2s infinite`, // Pulsating animation
    }}
  >
    Upgrade
  </Button>
  <Button
      variant="outlined"
      color="secondary"
      startIcon={
        <img
          src={btn_convert}
          alt="icon"
          style={{
            maxWidth: '24px',
            maxHeight: '24px',
          }}
        />
      }
        onClick={() => handleClick('Coming Soon')} // Pass custom message here
        sx={{
          margin: '5px',
          padding: '8px 16px',
          fontSize: '0.8rem',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#FF512F',
          borderColor: '#FF512F',
          boxShadow: '0 0 15px #eee',
          borderRadius: '8px',
          width: '40%',
          transition: '0.5s ease-in-out',
        }}
      >
        Convert
      </Button>
 

</Box>

      {error && (
        <Typography
          variant="h7"
          sx={{
            color: 'red',
            marginBottom: '20px',
          }}
        >
          Error: {error}
        </Typography>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Points claimed successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={open}
        autoHideDuration={3000} // Snackbar will automatically close after 3 seconds
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage} {/* Display the dynamic message here */}
        </Alert>
      </Snackbar>

      <Upgrade
        open={isBottomSheetOpen}
        onClose={() => setIsBottomSheetOpen(false)}
      />

    </Box>
  );
};

export default HomePage;
