import React from 'react';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import WalletIcon from '@mui/icons-material/Wallet';
const WalletNavigation = ({ value, setValue, index }) => (
  <BottomNavigationAction
    label="Wallet"
    icon={<WalletIcon  sx={{ color: value === index ? '#6A1B9A' : '#9E9E9E' }} />}
    value={index}
    selected={value === index}
    onClick={() => setValue(index)}
    showLabel
    sx={{
      '&.Mui-selected': {
        color: '#6A1B9A', // Dark purple for selected state
      },
      '&.MuiBottomNavigationAction-root': {
        color: '#9E9E9E', // Gray for non-selected state
      }
    }}
  />
);

export default WalletNavigation;
