import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Box, Typography } from '@mui/material';
import 'react-circular-progressbar/dist/styles.css';
import '../css/CircularWaveProgressBar.css'; 
import gifImage from '../images/mars.gif'; // Replace with your GIF path

const CircularWaveProgressBar = ({ timeValue, value }) => {
  const radius = 200;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        left: 0,
        padding: 2,
        backgroundColor: 'rgb(0,0,0,0)',
        borderRadius: '16px',
        overflow: 'hidden', // Ensure particles stay inside the box
      }}
    >
     
      {/* Container for Progress Bar */}
      <Box sx={{ position: 'relative', width: radius, height: radius }}>
        {/* Shadow Progress Bar (Larger) */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgressbar
            value={value}
            styles={buildStyles({
              pathColor: 'rgba(255, 255, 255, 0.8)', // Shadow color with opacity
              trailColor: 'transparent', // Make the trail transparent
              pathTransitionDuration: 0.5,
            })}
          />
        </Box>

        {/* Main Progress Bar (Smaller) */}
        <Box
          sx={{
            position: 'absolute',
            top: '5%', // Position it slightly smaller and centered
            left: '5%',
            width: '90%',
            height: '90%',
          }}
        >
          <svg style={{ position: 'absolute', width: '0', height: '0' }}>
            <defs>
              <linearGradient id="gradientColors" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#ff512f" />
                <stop offset="100%" stopColor="#dd2476" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgressbar
            value={value}
            styles={buildStyles({
              pathColor: 'url(#gradientColors)', // Use the defined gradient
              trailColor: '#d6d6d6',
              textSize: '16px',
              pathTransitionDuration: 0.5, // Smooth transition
              text: {
                fill: '#fff', // Default text color
                fontSize: '16px', // Default text size
              },
            })}
            text={null} // Ensure no text is displayed from this CircularProgressbar
          />
        </Box>

        {/* Conditionally render the GIF when progress is less than 100% */}
        {value < 100 && (
          <Box
            component="img"
            src={gifImage}
            alt="Progress GIF"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '50%',
              transform: 'translate(-50%, -50%)', // Center the GIF
              zIndex: 1, // Ensure the GIF is above the percentage text
            }}
          />
        )}

        {/* Display animated glowing shadow when progress is 100% */}
        {value === 100 && (
          <Typography
            className="glowing-text"
            sx={{
              textAlign: 'center',
              fontSize: '1.5rem',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            Ready!
          </Typography>
        )}
      </Box>

      {/* Percentage Text Below Progress Bar */}
      <Typography
        sx={{
          marginTop: 2,
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '1rem',
        }}
      >
        {value < 100 ? `${Math.round(value)}%` : `${Math.round(value)}%`}
      </Typography>

      {/* Additional Text with Time */}
      <Typography
        variant="h6"
        sx={{
          marginTop: 2,
          color: '#0077be',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Next claim at: {timeValue}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          marginTop: 1,
          color: '#fff',
          textAlign: 'center',
        }}
      >
        Time remaining
      </Typography>
    </Box>
  );
};

export default CircularWaveProgressBar;
