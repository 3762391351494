import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Modal, Skeleton, TextField } from '@mui/material'; // Import TextField for input
import axios from 'axios';
import Web3 from 'web3';
import QRCode from 'qrcode.react';
import coinsData from '../coins/coins.json';
import tokensData from '../coins/tokens.json';
import { getPriceFromCoinGecko } from '../utils/priceUtils';
import Lottie from 'lottie-react';
import wallet_lottie from '../lottie/wallet.json';
import TransferModal from './TransferModal';
import IconButton from '@mui/material/IconButton';
import LockPersonIcon from '@mui/icons-material/LockPerson';


const WalletPage = () => {

  const [walletInfo, setWalletInfo] = useState({ wallet_address: '', private_key: '' });
  const [assets, setAssets] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [transferAsset, setTransferAsset] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [openBackupModal, setOpenBackupModal] = useState(false); // State for backup modal
  const [confirmText, setConfirmText] = useState(''); // State for user confirmation input


  const tg = window.Telegram.WebApp;
  const initDataUnsafe = tg.initDataUnsafe; // parsed data



  useEffect(() => {
    const fetchWalletInfo = async () => {
      try {
        const response = await axios.post('https://xcosmos.top/api/user', {
          initDataUnsafe: initDataUnsafe
        });
        const { wallet_address, private_key } = response.data;
        setWalletInfo({ wallet_address, private_key });
      } catch (error) {
        setError(error.response.data.message);
      }
    };

    fetchWalletInfo();
  }, []); 

  useEffect(() => {
    const fetchBalancesAndPrices = async () => {
      setLoading(true); // Start loading

      const web3 = new Web3();

      const fetchCoinBalances = async () => {
        return Promise.all(
          Object.keys(coinsData).map(async (key) => {
            const coin = coinsData[key];
            const provider = new Web3.providers.HttpProvider(coin.rpcUrl);
            web3.setProvider(provider);
            const balance = await web3.eth.getBalance(walletInfo.wallet_address);
            const balanceInUnits = Web3.utils.fromWei(balance, 'ether');
            return { ...coin, balance: parseFloat(balanceInUnits) };
          })
        );
      };

      const fetchTokenBalances = async () => {
        try {
          return Promise.all(
            Object.keys(tokensData).map(async (key) => {
              const token = tokensData[key];
              const provider = new Web3.providers.HttpProvider(token.rpcUrl);
              web3.setProvider(provider);

              const abi = [
                {
                  constant: true,
                  inputs: [{ name: 'owner', type: 'address' }],
                  name: 'balanceOf',
                  outputs: [{ name: 'balance', type: 'uint256' }],
                  payable: false,
                  stateMutability: 'view',
                  type: 'function',
                },
              ];

              const contract = new web3.eth.Contract(abi, token.contract);
              try {
                const balance = await contract.methods.balanceOf(walletInfo.wallet_address).call();
                const balanceInUnits = Web3.utils.fromWei(balance, 'ether');
                return { ...token, balance: parseFloat(balanceInUnits) };
              } catch (contractError) {
                return { ...token, balance: 0 };
              }
            })
          );
        } catch (error) {
          setError('Error fetching token balances');
          return [];
        }
      };

      try {
        const [coinsWithBalances, tokensWithBalances] = await Promise.all([
          fetchCoinBalances(),
          fetchTokenBalances(),
        ]);
        const allAssets = [...coinsWithBalances, ...tokensWithBalances];
        const ids = allAssets.map((asset) => asset.coingeckoId).join(',');
        const prices = await getPriceFromCoinGecko(ids);

        const assetsWithPrices = allAssets.map((asset) => {
          const price = prices[asset.coingeckoId]?.usd || 0;
          return { ...asset, price };
        });

        setAssets(assetsWithPrices);

        const total = assetsWithPrices.reduce(
          (acc, asset) => acc + asset.balance * asset.price,
          0
        );
        setTotalBalance(total);
      } catch (error) {
        setError('Error fetching balances or prices');
      } finally {
        setLoading(false); // End loading
      }
    };

    if (walletInfo.wallet_address) {
      fetchBalancesAndPrices();
    }
  }, [walletInfo.wallet_address]);

  const handleAssetClick = (asset) => {
    setTransferAsset(asset);
  };


  const handleBackupClick = () => {
    setOpenBackupModal(true);
  };

  const handleConfirmChange = (event) => {
    setConfirmText(event.target.value);
  };

  const closeBackupModal = () => {
    setOpenBackupModal(false);
    setConfirmText(''); // Clear the input
  };
  return (
    <>
      <Box
        sx={{
          background:'#000',
          backgroundImage: `
          radial-gradient(circle at 40% 50%, #610b4b 0%, #a30d8a00 40%),
          radial-gradient(circle at 65% 60%, #420a50 0%, #580e7500 45%),
          radial-gradient(circle at 40% 60%, #076066 0%, #17777700 45%)`,
        backgroundBlendMode: 'screen',
   
          marginBottom: '56px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          boxShadow={'0 0 8px 2px rgba(0, 150,255, 0.3)'}
          sx={{
            borderRadius: '0 0 20px 20px',
            background: 'rgba(0, 10, 50, 0.5)',
            width: '100%',
            minHeight: '200px',
            marginTop: '0',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
            flexDirection: 'column',
          }}
        >
 <IconButton
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            aria-label="backup"
            color="primary"
            onClick={handleBackupClick}
          >
            <LockPersonIcon />
          </IconButton>

          <Lottie
            animationData={wallet_lottie}
            loop={true}
            style={{ width: 150, height: 150 }}
          />

          <Typography
            color={'whitesmoke'}
            sx={{ textShadow: '2px 2px 15px #00ffff', p: '10px' }}
          >
            Balance : ${loading ? <Skeleton width={80}   style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}  /> : totalBalance.toFixed(2)}
          </Typography>

          <Button
            sx={{
              backgroundColor:'rgb(0,200,255,1)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              marginTop: '10px',
              borderRadius: '15px',
              fontSize: '0.7rem',
              height: '40px',
              width: '150px',
              color: 'white',
              borderColor: 'rgba(0, 150, 255, 0.7)',
            }}
            onClick={() => setOpenModal(true)}
          >
            Receive
          </Button>
        </Box>

        <Box
          sx={{
            borderRadius: '20px',
            background: 'rgba(20, 20, 20, 0.5)',
            width: '98%',
            minHeight: '60px',
            height: '100%',
            marginTop: '50px',
            marginBottom: '50px',
            alignItems: 'center',
            justifyItems: 'center',
            padding: '20px',
          }}
        >
          {loading ? (
            Array.from(new Array(4)).map((_, index) => (
              <Box
                key={index}
                boxShadow={'0 0 8px 2px rgba(0, 150,255, 0.1)'}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  borderRadius: '20px',
                  background: 'rgba(20, 20, 20, 0.5)',
                  width: '100%',
                  minHeight: '50px',
                  height: 'auto',
                  marginTop: '30px',
                  alignItems: 'center',
                  color: 'white',
                  padding: '10px',
                }}
              >
                <Skeleton variant="circular" width={30} height={30}   style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}  />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: 'white',
                    padding: '10px',
                  }}
                >
                  <Skeleton width={80} height={20}   style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
                  <Skeleton width={50} height={20}   style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: 'white',
                    padding: '10px',
                  }}
                >
                  <Skeleton width={80} height={20}   style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}  />
                  <Skeleton width={50} height={20}    style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
                </Box>
              </Box>
            ))
          ) : error ? (
            <Typography
              variant="h6"
              sx={{ color: 'red', textAlign: 'center' }}
            >
              {error}
            </Typography>
          ) : assets.length === 0 ? (
            <Typography
              variant="h6"
              sx={{ color: 'white', textAlign: 'center' }}
            >
              No assets found.
            </Typography>
          ) : (
            assets.map((asset, index) => (
              <Box
              boxShadow={'0 0 8px 2px rgba(0, 150,255, 0.1)'}
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  borderRadius: '20px',
                  background: 'rgba(20, 20, 20, 0.5)',
                  width: '100%',
                  minHeight: '50px',
                  height: 'auto',
                  marginTop: '30px',
                  alignItems: 'center',
                  color: 'white',
                  padding: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => handleAssetClick(asset)}
              >
                <img
                  src={asset.iconUrl || 'https://via.placeholder.com/30'}
                  alt={asset.name}
                  width="30"
                  height="30"
                />

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: 'white',
                    padding: '10px',
                  }}
                >
                  <Typography
                    fontSize={'0.8rem'}
                    variant="h6"
                    sx={{ marginLeft: '10px' }}
                  >
                    {asset.name}
                  </Typography>
                  <Typography
                    fontSize={'0.8rem'}
                    variant="h6"
                    sx={{ marginLeft: '10px' }}
                  >
                    ${asset.price?.toFixed(3)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: 'white',
                    padding: '10px',
                  }}
                >
                  <Typography
                    fontSize={'0.8rem'}
                    variant="h6"
                    sx={{ marginLeft: '10px' }}
                  >
                    {asset.balance.toFixed(4)}
                  </Typography>
                  <Typography
                    fontSize={'0.8rem'}
                    variant="h6"
                    sx={{ marginLeft: '10px' }}
                  >
                    ${(asset.balance * asset.price).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="receive-modal-title"
        aria-describedby="receive-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
            borderRadius: '10px',
          }}
        >
          <Typography
            fontStyle={'bold'}
            id="receive-modal-title"
            variant="h6"
            component="h2"
          >
            Wallet Address
          </Typography>
          <Typography
            id="receive-modal-description"
            sx={{ padding: '5px', mt: 2, wordWrap: 'break-word' }}
          >
            {walletInfo.wallet_address}
          </Typography>
          <Box marginTop={'10px'}>
            <QRCode value={walletInfo.wallet_address} size={150} />
          </Box>
        </Box>
      </Modal>

        {/* Backup Private Key Modal */}
        <Modal open={openBackupModal} onClose={closeBackupModal}>
          <Box
            boxShadow={'0 0 8px 2px rgba(0, 150,255, 0.3)'}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              backgroundColor: 'rgba(0,0,0,0.9)',
              padding: '20px',
              borderRadius: '10px',
              textAlign: 'center',
            }}
          >
            <Typography variant="p" gutterBottom sx={{ color: 'whitesmoke' }}>
              Type "CONFIRM" to Reveal Your Private Key
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type CONFIRM"
              value={confirmText}
              onChange={handleConfirmChange}
              sx={{
                input: { color: 'whitesmoke' },
                fieldset: { borderColor: 'rgba(0, 150, 255, 0.7)' },
                marginBottom: '20px',
              }}
            />
            {confirmText === 'CONFIRM' && (
              <Typography sx={{ wordBreak: 'break-word', color: 'red' }}>
                {walletInfo.private_key} <br></br> Do Not share your privateKey to anyone ! <br></br>
                this is a evm privateKey you can import it to the crypto wallets like metamask
              </Typography>
            )}
            <Button
              onClick={closeBackupModal}
              sx={{
                marginTop: '10px',
                color: 'whitesmoke',
                borderColor: 'rgba(0, 150, 255, 0.7)',
              }}
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </Modal>

      {/* Transfer Modal */}
      {transferAsset && (
        <TransferModal
          open={Boolean(transferAsset)}
          onClose={() => setTransferAsset(null)}
          asset={transferAsset}
          walletAddress={walletInfo.wallet_address}
          privateKey={walletInfo.private_key}
        />
      )}
    </>
  );
};

export default WalletPage;
