import React, { useEffect, useRef } from "react";

const Starfield = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight ;
    };

    setCanvasSize();

    let numStars = 3000; // Fewer stars on smaller screens
    const stars = [];
    let speed = canvas.width < 768 ? 2 : 3; // Slower speed on mobile
    let mouseX = canvas.width / 2;
    let mouseY = canvas.height / 2;

    // Precompute the star data
    function createStar() {
      let x, y;
      do {
        x = Math.random() * canvas.width;
        y = Math.random() * canvas.height;
      } while (Math.hypot(x - mouseX, y - mouseY) < 3);

      return {
        x,
        y,
        z: Math.random() * canvas.width,
        o: Math.random(),
        size: Math.random() * 2 + 1, // Random size between 3 and 6
      };
    }

    function populateStars() {
      stars.length = 0;
      for (let i = 0; i < numStars; i++) {
        stars.push(createStar());
      }
    }

    // Throttle mouse move to reduce event spam
    let lastMouseMoveTime = 0;
    const handleMouseMove = (event) => {
      const now = Date.now();
      if (now - lastMouseMoveTime < 16) return; // Throttle to ~60 FPS
      lastMouseMoveTime = now;

      mouseX = event.clientX;
      mouseY = event.clientY;
    };

    const handleKeyDown = (event) => {
      if (event.code === "ArrowUp" || event.code === "KeyW") {
        speed += 1;
      } else if (event.code === "ArrowDown" || event.code === "KeyS") {
        speed = Math.max(1, speed - 1);
      } else if (event.code === "NumpadAdd") {
        numStars += 100;
        populateStars();
      } else if (event.code === "NumpadSubtract") {
        numStars = Math.max(100, numStars - 100);
        populateStars();
      }
    };

    function updateStars() {
      context.clearRect(0, 0, canvas.width, canvas.height);

      for (let star of stars) {
        star.z -= speed;

        if (star.z <= 0) {
          Object.assign(star, createStar());
          star.z = canvas.width;
        }

        const sx = (star.x - mouseX) * (canvas.width / star.z) + mouseX;
        const sy = (star.y - mouseY) * (canvas.width / star.z) + mouseY;
        const size = (1 - star.z / canvas.width) * star.size;
        context.fillStyle = "white";
        context.fillRect(sx, sy, size, size);
      }

      requestAnimationFrame(updateStars);
    }

    populateStars();
    updateStars();

    // Event listeners
    window.addEventListener("resize", setCanvasSize);
    canvas.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", setCanvasSize);
      canvas.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        backgroundImage: `
        radial-gradient(circle at 40% 50%, #610b4b 0%, #a30d8a00 40%),
        radial-gradient(circle at 65% 60%, #420a50 0%, #580e7500 45%),
        radial-gradient(circle at 40% 60%, #076066 0%, #17777700 45%)`,
      backgroundBlendMode: 'screen',
        position: "fixed", // Position for z-index to work
        zIndex: 0, // Set the desired z-index
        top: 0, // Ensure the canvas covers the entire screen
        left: 0,
        width: "100%",
        height: "100%",
      }}
    />
  );
};

export default Starfield;
