// src/components/LoadingPage.js
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingPage = () => {
  return (
    <Box 
      sx={{
        background:'#000',
        backgroundImage: `
        radial-gradient(circle at 40% 50%, #610b4b 0%, #a30d8a00 40%),
        radial-gradient(circle at 65% 60%, #420a50 0%, #580e7500 45%),
        radial-gradient(circle at 40% 60%, #076066 0%, #17777700 45%)`,
        backgroundBlendMode: 'screen',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'rgb(0,10,20,1)', // Background color for loading screen
        color: 'whitesmoke',
        position: 'relative', // For positioning the version text at the bottom
        overflow: 'hidden',  // Prevents scrollbars
      }}
    >

      {/* Xwallet text at the top with custom color for 'X' */}
      <Typography 
        textAlign={'center'} 
        variant="h5" 
        sx={{ 
          position: 'absolute', 
          top: 30, // Set the position to top
          textShadow: '2px 2px 10px #00ffff',
          mb: 2 
        }}
      >
        <span style={{ color: '#ff1493' }}>X</span>wallet
      </Typography>

      {/* Centered loading progress bar with dark gray color */}
      <CircularProgress sx={{ color: '#A9A9A9', mb: 2 }} />

      {/* Version text at the bottom */}
      <Typography variant="h7" sx={{ 
        position: 'absolute',
        fontWeight:'bold',
        bottom: 10, // Distance from the bottom
      }}>
        v 1.0.0
      </Typography>
    </Box>
  );
};

export default LoadingPage;
