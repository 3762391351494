import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Snackbar, Alert, Skeleton } from '@mui/material';
import axios from 'axios';
import userimg from '../images/profile.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Lottie from 'lottie-react';
import referral_lottie from '../lottie/referral.json';

const truncateString = (input, maxLength) => {
  const str = String(input); // Convert the input to a string
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
};

const InvitePage = () => {
  const [referrals, setReferrals] = useState([]);

  const [loadingReferrals, setLoadingReferrals] = useState(true);
  const [loadingUserId, setLoadingUserId] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const tg = window.Telegram.WebApp;
  const initDataUnsafe = tg.initDataUnsafe; // parsed data
   const id = initDataUnsafe?.user?.id
  useEffect(() => {
    fetchReferrals();
  }, []);


  const fetchReferrals = async () => {
    try {
      const response = await axios.post('https://xcosmos.top/api/user/referrals', {
        initDataUnsafe: initDataUnsafe
      });
      setReferrals(response.data);
      console.log(JSON.stringify(response.data))
    } catch (error) {
      console.error('Error fetching referrals:', error);
    } finally {
      setLoadingReferrals(false);
      setLoadingUserId(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://t.me/Xwallet_mini_bot/xwallet/join?startapp=ref-${id}`);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        background:'#000',
        backgroundImage: `
        radial-gradient(circle at 40% 50%, #610b4b 0%, #a30d8a00 40%),
        radial-gradient(circle at 65% 60%, #420a50 0%, #580e7500 45%),
        radial-gradient(circle at 40% 60%, #076066 0%, #17777700 45%)`,
      backgroundBlendMode: 'screen',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        marginBottom: '56px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Top box */}
      <Box
        boxShadow={'0 0 8px 2px rgba(0, 150,255, 0.3)'}
        sx={{
          borderRadius: '0 0 20px 20px',
          background: 'rgba(0, 10, 50, 0.5)',
          width: '100%',
          minHeight: '60px',
          marginTop: '0',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
          flexDirection: 'column',
        }}
      >
        <Lottie
          animationData={referral_lottie}
          loop={true}
          style={{ width: 150, height: 150 }}
        />
        <Typography variant='h7' color={'white'} textAlign={'center'}>
          👥 Invite Friends
        </Typography>
      </Box>

      {/* Main content */}
      <Box
        sx={{
          borderRadius: '0 0 20px 20px',
          background: 'rgba(20, 20, 20, 0.13)',
          width: '98%',
          minHeight: '60px',
          height: '100%',
          marginTop: '10px',
          marginBottom: '50px',
          alignItems: 'center',
          justifyItems: 'center',
          padding: '20px',
        }}
      >
        {/* Referral Info */}
        <Box
          sx={{
            padding: '10px',
            borderRadius: '10px',
            background: 'rgba(0, 50, 255, 0.13)',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: '20px',
          }}
        >
          {loadingUserId ? (
            <Skeleton variant="text" width={150} />
          ) : (
            <>
              <Typography fontSize={'0.8rem'} variant='p' color={'white'} textAlign={'top'}>
                <strong> My Referrals: {referrals.length}</strong>
              </Typography>
              <Button
                startIcon={<ContentCopyIcon />}
                variant="outlined"
                onClick={handleCopy}
                sx={{ borderRadius: '15px', fontSize: '0.7rem', marginLeft: '20px', height: '50px' }}
              >
                Copy link
              </Button>
            </>
          )}
        </Box>

        <Typography marginTop={'25px'} variant='p' color={'white'} textAlign={'center'}>
          🎁 Invite your friends and get paid 15% of their claiming!
        </Typography>

        {/* Conditional Rendering: No Referrals Message */}
        {loadingReferrals ? (
          <Skeleton variant="rectangular" height={100} />
        ) : referrals.length === 0 ? (
          <Typography variant='p' color={'white'} textAlign={'center'} marginTop={'80px'}>
            <br /> <br /> <br /> <br /> You have no referrals yet. Invite your friends using the link above!
          </Typography>
        ) : (
          referrals.map((referral) => (
            <Box
              key={referral.id}
              boxShadow={'0 0 8px 2px rgba(0, 150,255, 0.13)'}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                borderRadius: '20px',
                background: 'rgba(20, 20, 20, 0.5)',
                width: '100%',
                minHeight: '50px',
                height: 'auto',
                marginTop: '30px',
                alignItems: 'center',
                color: 'white',
                padding: '10px',
              }}
            >
              <Box
                bgcolor='rgb(0,0,0,0)'
                component="img"
                src={userimg}
                sx={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                alt="user"
              />
              <Typography sx={{ fontSize: '0.8rem', padding: '15px', overflowWrap: 'break-word', color: 'white' }} variant='p'>
                👤{truncateString(referral.id, 3)}
              </Typography>
              <Typography sx={{ fontSize: '0.8rem', overflowWrap: 'break-word', color: 'white', marginLeft: '5px', marginRight: '10px' }} variant='p'>
                🤖{truncateString(referral.name, 3)}
              </Typography>
              <Typography sx={{ fontSize: '0.8rem', overflowWrap: 'break-word', color: 'white', marginRight: '5px', marginLeft: '10px' }} variant='p'>
                level:{referral.level}
              </Typography>
            </Box>
          ))
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Referral link copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InvitePage;
